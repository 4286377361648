.accountFamilyBasicWrapper {
  background: url('../../../../public/img/account_family_basic_background.webp');
  background-size: contain;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}

.accountFamilyBasicMobileWrapper {
  background: url('../../../../public/img/account_family_basic_background_mobile.webp');
  background-size: contain;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}

.accountFamilyPlusWrapper {
  background: url('../../../../public/img/account_family_plus_background.webp');
  background-size: contain;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}

.accountFamilyPlusMobileWrapper {
  background: url('../../../../public/img/account_family_plus_background_mobile.webp');
  background-size: contain;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}
